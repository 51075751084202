// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-agb-jsx": () => import("./../src/pages/agb.jsx" /* webpackChunkName: "component---src-pages-agb-jsx" */),
  "component---src-pages-imprint-jsx": () => import("./../src/pages/imprint.jsx" /* webpackChunkName: "component---src-pages-imprint-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-thank-you-jsx": () => import("./../src/pages/thank-you.jsx" /* webpackChunkName: "component---src-pages-thank-you-jsx" */)
}

